import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import { Loader } from '@components/_shared/loaders';
import { BaseButtonProps } from '@utils/types/baseComponents';

import styles from './styles.module.scss';

type Props = {
  children: string | JSX.Element;
  loading?: boolean;
  variant?:
    | 'contained'
    | 'outlined'
    | 'darkOutlined'
    | 'tanOutlined'
    | 'tanOutlinedTransparent'
    | 'tanContained'
    | 'darkTanContained'
    | 'darkContained'
    | 'lightContained'
    | 'blueContained'
    | 'whiteContained'
    | 'orangeContained';
  to?: string;
} & BaseButtonProps;

const Button = (props: Props) => {
  const {
    disabled,
    children,
    onClick,
    containerStyle,
    type = 'button',
    loading = false,
    variant = 'contained',
    to = undefined,
  } = props;

  return (
    <div
      className={clsx([
        styles.container,
        disabled && styles.disabled,
        styles[variant],
        containerStyle,
      ])}
    >
      {to ? (
        <Link href={to} passHref>
          <a>{children}</a>
        </Link>
      ) : (
        <button disabled={disabled} type={type} onClick={onClick}>
          {loading ? <Loader /> : children}
        </button>
      )}
    </div>
  );
};

export default Button;
